import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from './components/Navbar'
import Home from './Pages/Home';
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div>
        <Navbar />

        <div>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
        </div>

      </div >
      <Footer />
    </Router>
  );
}

export default App;
