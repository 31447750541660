import React from 'react';
import {Link} from 'react-router-dom'

function Social() {
  return (
    <div>
        <div className='row'>
            <div className="col-1">
                <button
                    className="btn"
                    name="email"
                    type="button"
                    onClick={()=> window.open("mailto:career.k9w@gmail.com")}
                    >
                    <i className='far fa-envelope link-light'>

                    </i>
                </button>
            </div>
            <div className="col-1">
                <button
                    className='btn'
                    name="youtube"
                    type="button"
                    onClick={()=> window.open("https://www.youtube.com/channel/UCU0O8RWEvLTq3Du2-VFR2Ew", "_blank")}
                >
                    <i className='fab fa-youtube link-light'></i>
                </button>
            </div>
            <div className='col-1'>
                <button
                    className="btn"
                >
                    <i className="fab fa-github link-light"></i>
                </button>
            </div>
            <div className='col-1'>
                <button
                    className="btn"
                >
                    <i className="fab fa-linkedin-in link-light"></i>
                </button>
            </div>
        </div>
    </div>
  )
}

export default Social;
