import React from 'react';

function Content() {
    return (
        <div>
            <div className="content">
                <div className="content__container">
                    <p className="content__container__text">
                        K9 are
                    </p>
                    <ul className="content__container__list">
                        <li className="content__container__list__item">TEAM! </li>
                        <li className="content__container__list__item">Warrios!</li>
                        <li className="content__container__list__item">Union!</li>
                        <li className="content__container__list__item">Workspace!</li>
                    </ul>
                </div>
            </div>
            <div className='py-5'></div>
            <div className="position-relative py-5">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <p className='text-sm-start fs-6 lh-sm' style={{ color: "#808285" }}>Hello! 👋 We are K9w.</p>
                </div>
            </div>
        </div>
    )
}

export default Content;
