import React from 'react';
import Social from '../components/Social';
import Content from '../components/Content';
import Logo from '../components/Logo';

import k9w from '../assets/img/k9.jpg';

function Home() {
  return (
    <div>
      <div className="col">
        <div className='container' style={{ height: 500 }}>
          <div className='py-5'></div>
          <div className='py-5'></div>
          <Content />
        </div>

        <Logo />

        <div id="about" className='container' style={{ height: 550 }}>
          <div className='row'>

            <h3 className='d-flex justify-content-center'>About</h3>

            <div className='col-5 d-flex align-items-center'>
              <p>We are K9w and we outsourcing based in Mongolia, working with clients worldwide. We specialize in Web application and DevOps.</p>

            </div>
            <div className='col-2'></div>
            <div className='col-5'>
              <img src={k9w} alt="" className='col-12' />

              <div className='mt-4 justify-content-end'>
                <Social />
              </div>
            </div>

            {/* <div className='col-7 mt-4'>
              <p className='text-secondary'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione id alias, perferendis, reprehenderit, non distinctio itaque impedit maxime asperiores quas eveniet. Dolores esse dolore impedit unde, ut doloremque pariatur magni!
              </p>
            </div> */}
          </div>
        </div>

        <div id="services" className='mb-5 ' style={{ height: 550, backgroundColor: "#102138" }}>
          <div className='container'>
            <div className='row'>
              <div className='py-5'></div>
              <h3 className='d-flex justify-content-center mb-4'>Services</h3>

              <div className='row mb-4'>

                <div className='col-4 mx-auto text-center'>
                  <div className="card rounted shadow-lg text-dark">
                    <div className="card-body">
                      <h5 className="card-title">API first</h5>
                      <p className="card-text"></p>
                    </div>
                  </div>
                </div>
                <div className='col-4 mx-auto text-center'>
                  <div className="card rounted shadow-lg text-dark">
                    <div className="card-body">
                      <h5 className="card-title">Performance & Scale</h5>
                      <p className="card-text"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>

                <div className='col-4 mx-auto text-center'>
                  <div className="card rounted shadow-lg text-dark">
                    <div className="card-body">
                      <h5 className="card-title">Security & Trust</h5>
                      <p className="card-text"></p>
                    </div>
                  </div>
                </div>
                <div className='col-4 mx-auto text-center'>
                  <div className="card rounted shadow-lg text-dark">
                    <div className="card-body">
                      <h5 className="card-title">Events</h5>
                      <p className="card-text"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Home;
