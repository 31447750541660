import React from 'react';
import Social from './Social';


function Footer() {
  return (
    <div className='footer1' style={{ height: "200px" }}>
      <div className='py-3'></div>
      <div className='container py-2'>

        <h6 className='d-flex justify-content-center'>Contact</h6>

        <div className='row'>

          <div className='col-4'>
            <div id='contact'>

              <div className='row'>
                <div className="col">
                  <ul className='navbar-nav '>
                    <li className='nav-item'><small>Contact us and we'll get <br /> back to you within 24 hours.</small></li> <br />
                    <li className='nav-item'>
                      <i className="fas fa-map-marker-alt"></i> <small>Mongolia, Ulaanbaatar</small>
                    </li>
                    <li className='nav-item'>
                      <i className="fas fa-phone"></i><small>+976 99149503</small>
                    </li>
                    <li className='nav-item'>
                      <i className="far fa-envelope"></i> <a href="mailto:career.k9w@gmail.com" className='text-decoration-none text-warning'><small>career.k9w@gmail.com</small></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-4 d-flex justify-content-center">
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <a to="/" className='nav-link text-warning'><small>Home</small></a>
              </li>
              <li className='nav-item'>
                <a href="#about" className='nav-link text-light'><small>About</small></a>
              </li>
              <li className='nav-item'>
                <a href="#services" className='nav-link text-light'><small>Services</small></a>
              </li>
              <li className='nav-item'>
                <a href="#contact" className='nav-link text-light'><small>Contact</small></a>
              </li>
            </ul>
          </div>

          <div className='col-4'>
            <Social />
          </div>
        </div>

      </div>

    </div>
  )
}

export default Footer;
