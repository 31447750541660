import React from 'react'

import docker from '../assets/img/docker.svg';
import aws from '../assets/img/aws-2.svg';
import github from '../assets/img/github-icon.svg';
import devops from '../assets/img/devops-2.svg';
import python from '../assets/img/python-5.svg';
import fastapi from '../assets/img/fastapi.svg';
import flask from '../assets/img/flask.svg';
import django from '../assets/img/django.svg';
import graphql from '../assets/img/graphql.svg';

function Logo() {
    return (
        <div className='shadow rounded py-1 mb-5' style={{ backgroundColor: "#102138" }}>
            <div className="carousel slide col-8 mx-auto">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="d-flex bd-highlight ">
                            <div className="p-2 bd-highlight">
                                <div className="row">
                                    <div className="col">
                                        <img src={docker} className="w-25" alt="" />
                                    </div>
                                    <div className="col">
                                        <img src={aws} className="w-25" alt="" />
                                    </div>
                                    <div className="col">
                                        <img src={github} className="w-25" alt="" />
                                    </div>
                                    <div className="col">
                                        <img src={devops} className="w-75" alt="" />
                                    </div>
                                    <div className="col">
                                        <img src={python} className="w-25" alt="" />
                                    </div>
                                    <div className='col'>
                                        <img src={fastapi} className="w-25" alt="" />
                                    </div>
                                    <div className='col'>
                                        <img src={flask} className="w-25" alt="" />
                                    </div>
                                    <div className='col'>
                                        <img src={django} className="w-25" alt="" />
                                    </div>
                                    <div className='col'>
                                        <img src={graphql} className="w-25" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Logo