import React from 'react';
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className='shadow rounded' >
      <div className='container'>


        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <p className="navbar-brand fw-bolder" style={{ color: "#FFE51B" }}>K9w <i style={{ color: "white" }} className="fas fa-code"></i></p>
            <div className="d-flex">
              <ul className='navbar-nav'>
                <li className='nav-item'>
                  <Link to="/" className='nav-link text-warning'>Home</Link>
                </li>
                <li className='nav-item'>
                  <a href="#about" className='nav-link text-secondary'>About</a>
                </li>
                <li className='nav-item'>
                  <a href="#services" className='nav-link text-secondary'>Services</a>
                </li>
                <li className='nav-item'>
                  <a href="#contact" className='nav-link text-secondary'>Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Navbar;
